<template>
 <div class="teilen-knopf-box profile-heading__icon">

    <navigator-share class="social-inline__item flex-fill"
    v-if="isItMobile && webShareApiSupported"
    v-bind:url="currentPath"
    v-bind:title="'Tripmeister - Reise, so wie du bist.'"
    v-bind:on-error="onShareError"
    v-bind:on-success="onShareSuccess"
    >
    <template v-slot:clickable>
        <button class="btn btn-icon-only btn-dark btn-share">
            <span class="material-icons share-icon">share</span>
        </button>
    </template>
</navigator-share>

<a :href="shareViaEmail" v-else class="btn btn-icon-only btn-dark btn-share">
    <span class="material-icons">share</span>
</a>

</div>

</template>
<script>
    import NavigatorShare from 'vue-navigator-share';
    import { isMobile } from 'mobile-device-detect';


    export default {
        name: "shareButton",
        components: {
            NavigatorShare
        },
        data() {
            return {
                isItMobile: isMobile ? true : false,
            }
        },
        computed: {
            currentPath(){
                return window.location.href;
            },
            webShareApiSupported() {
                return navigator.share
            },
            shareViaEmail(){
                return "mailto:?body="+this.currentPath;
            },
        },
        methods: {
            onShareError(err) {
                alert(err);
                console.log(err);
            },
            onShareSuccess(err) {
                console.log(err);
            },
        }
    }
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .teilen-knopf-box{
    display:inline-block;
}

.btn-share span.material-icons{
   margin-top: 5px;
   margin-right: 1px;
   text-align: center;
   border: 1px solid #000000;
   border-radius: 50px;
   height: 40px;
   width: 40px;
   display: inline-block;
   line-height: 36px;
   text-align: center;
   font-size: 24px;
   color: #636163;
}

.btn-dark {
    color: #686B6F;
    border-color: #686B6F;
    background: transparent;
}

.btn-share {
    background-color: white !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: none;

    &:hover{
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.65);
    }
}

@include responsive($scroll-breakpoint){
    .btn-share {
        color: #FFFFFF;
        border-color: #FFFFFF;
        background: transparent;
    }
}



</style>
